import Dashboard from "views/Dashboard.js";
import UserPage from "views/User.js";
import UserDetail from "views/UserDetail";
import { GetRole } from "lib/helper/cookie-helper";
import Logout from "views/Logout";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: <Dashboard />,
    layout: "/admin",
    showSidebar: true
  },
  {
    path: "/user-page",
    name: "Öğrenci Profil",
    icon: "nc-icon nc-circle-10",
    component: <UserPage />,
    layout: "/admin",
    showSidebar: false
  },
  {
    path: "/user-detail",
    name: "Öğrenci Detay",
    icon: "nc-icon nc-chart-bar-32",
    component: <UserDetail />,
    layout: "/admin",
    showSidebar: false
  },
  {
    path: "/logout",
    name: "Çıkış Yap",
    icon: "nc-icon nc-simple-remove",
    component: <Logout />,
    layout: "/admin",
    showSidebar: true
  }
];

const userSidebar = [
  {
    path: "/user-page",
    name: "Profilim",
    icon: "nc-icon nc-circle-10",
    component: <UserPage />,
    layout: "/student",
    showSidebar: "true"
  },
  {
    path: "/user-detail",
    name: "Raporum",
    icon: "nc-icon nc-chart-bar-32",
    component: <UserDetail />,
    layout: "/student",
    showSidebar: "true"
  },
  {
    path: "/logout",
    name: "Çıkış Yap",
    icon: "nc-icon nc-simple-remove",
    component: <Logout />,
    layout: "/student",
    showSidebar: "true"
  }
]

const userRole = GetRole();

export default userRole === "teacher" ? routes : userSidebar;
