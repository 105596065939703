import axios from "axios";
import React from "react";
import ReactApexChart from "react-apexcharts";

class TwoBarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          title: {
            text: "Ortalamalar",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },
    };
  }

  fetchData = () => {
    axios
      .get("https://edunimbus-django-backend.onrender.com/users/17/")
      .then((response) => {
        const allSubCat = response.data.result.data6;
        const averageForPerson = response.data.result.data5;
        const groupedData = {};
        allSubCat.forEach((item) => {
          switch (item.genreID) {
            case 1:
            case 2:
            case 3:
              groupedData["Görsel Uzamsal"] =
                groupedData["Görsel Uzamsal"] || [];
              groupedData["Görsel Uzamsal"].push(item);

              break;
            case 4:
            case 5:
              groupedData["Matematiksel Mantıksal"] =
                groupedData["Matematiksel Mantıksal"] || [];
              groupedData["Matematiksel Mantıksal"].push(item);
              break;
            case 6:
              groupedData["Müziksel Ritmik"] =
                groupedData["Müziksel Ritmik"] || [];
              groupedData["Müziksel Ritmik"].push(item);
              break;
            case 7:
            case 8:
            case 9:
              groupedData["Dikkat Hafıza"] =
                groupedData["Dikkat Hafıza"] || [];
              groupedData["Dikkat Hafıza"].push(item);
              break;
            case 10:
              groupedData["Sözel Dilbilimsel"] =
                groupedData["Sözel Dilbilimsel"] || [];
              groupedData["Sözel Dilbilimsel"].push(item);
              break;
            default:
              break;
          }
        });

        const totalPercentages = {};
        const totalAveragePoints = {};
        const totalStudentAmounts = {};

        for (const category in groupedData) {
          const categoryData = groupedData[category];
          const totalPercentage = categoryData.reduce(
            (total, item) => total + item.percentile,
            0
          );
          totalPercentages[category] = totalPercentage;
        }

        for (const category in groupedData) {
          const categoryData = groupedData[category];
          const totalAveragePoint = categoryData.reduce((total, item) => total + item.averagePoints, 0);
          totalAveragePoints[category] = parseFloat(totalAveragePoint.toFixed(2));
        }

        for (const category in groupedData) {
          const categoryData = groupedData[category];
          const totalStudentAmount = categoryData.reduce(
            (total, item) => total + item.studentAmount,
            0
          );
          totalStudentAmounts[category] = totalStudentAmount;
        }

        const labels = Object.keys(totalPercentages);
        const data = labels.map((category) => totalPercentages[category]);

        const userData = totalPercentages;
        const averageData = totalAveragePoints;

        const user = Object.values(userData);
        const average = Object.values(averageData);

        const formatUserData = [averageForPerson.percentile].concat(user)
        const formatAverageData = [80].concat(average);

        const dataLabel = totalStudentAmounts;

        const keys = Object.keys(dataLabel);
        const values = Object.values(dataLabel);
        const formattedData = keys.map((key, index) => [key, `Toplam Oyuncu Sayısı: ${values[index]}`]);

        const catDataLabels = [['Çalışma Raporu', `Toplam Oyuncu Sayısı: ${averageForPerson.studentAmount}`]].concat(formattedData);

        this.setState({
          series: [
            {
              name: "Öğrenci Ortalaması",
              data: formatUserData,
            },
            {
              name: "Genel Ortalama",
              data: formatAverageData,
            },
          ],
          options: {
            ...this.state.options,
            xaxis: {
              categories: catDataLabels,
            },
          },
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={350}
        />
      </div>
    );
  }
}
export default TwoBarChart;
