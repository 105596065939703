import React from "react";

import {
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";

function User() {
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              <div className="image">
              </div>
              <CardBody>
                <div className="author">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar border-gray"
                      src={require("assets/img/logo-small.png")}
                    />
                    <h5 className="title">Can Güner</h5>
                  </a>
                  <p className="description">@canguner</p>
                  <h5>
                    12
                    <small>Sınıf</small>
                  </h5>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default User;
