import StudentTable from "components/UserCharts/StudentTable";
import React from "react";
import {
  Col,
} from "reactstrap";

function DashboardPage() {
  return (
    <>
      <div className="content">
          <Col md={12}>
            <StudentTable />
          </Col>
      </div>
    </>
  );
}

export default DashboardPage;
