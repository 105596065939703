import axios from "axios";
import React from "react";
const { default: ReactApexChart } = require("react-apexcharts");

class PieChartUsed extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            series: [],
            options: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },


        };
    }

    fetchData = () => {
        axios.get('https://edunimbus-django-backend.onrender.com/users/17/')
            .then((response) => {
                const allSubCat = response.data.result.data2;
                const groupedData = {};
                allSubCat.forEach(item => {
                    switch (item.genreID) {
                        case 1:
                        case 2:
                        case 3:
                            groupedData['Görsel Uzamsal'] = groupedData['Görsel Uzamsal'] || [];
                            groupedData['Görsel Uzamsal'].push(item);

                            break;
                        case 4:
                        case 5:
                            groupedData['Matematiksel Mantıksal'] = groupedData['Matematiksel Mantıksal'] || [];
                            groupedData['Matematiksel Mantıksal'].push(item);
                            break;
                        case 6:
                            groupedData['Müziksel Ritmik'] = groupedData['Müziksel Ritmik'] || [];
                            groupedData['Müziksel Ritmik'].push(item);
                            break;
                        case 7:
                        case 8:
                        case 9:
                            groupedData['Dikkat Hafıza'] = groupedData['Dikkat Hafıza'] || [];
                            groupedData['Dikkat Hafıza'].push(item);
                            break;
                        case 10:
                            groupedData['Sözel Dilbilimsel'] = groupedData['Sözel Dilbilimsel'] || [];
                            groupedData['Sözel Dilbilimsel'].push(item);
                            break;
                        default:
                            break;
                    }
                });
                const totalPercentages = {};

                for (const category in groupedData) {
                    const categoryData = groupedData[category];
                    const totalPercentage = categoryData.reduce((total, item) => total + item.percentage, 0);
                    totalPercentages[category] = totalPercentage;
                }

                const labels = Object.keys(totalPercentages);
                const data = labels.map(category => totalPercentages[category]);

                this.setState({
                    series: data,
                    labels: labels,
                });
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }

    componentDidMount() {
        this.fetchData();
    }
    
    render() {
        return (
            <>
                <div className='d-flex flex-wrap flex-stack mb-6'>
                    <h3 className='fw-bolder my-2'>
                        Öğrencilerin Alt Zeka Alanlarına Göre Egzersiz Sayısının Yüzdelik Dağılımı
                    </h3>
                </div>

                <div className="d-flex">
                    <div className="col-md-6 offset-md-2">
                        <ReactApexChart options={this.state.options} series={this.state.series} type="pie" width={750} />
                    </div>
                </div>
            </>
        );
    }
}

export default PieChartUsed