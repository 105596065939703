import axios from 'axios';
import React from 'react';
const { default: ReactApexChart } = require("react-apexcharts");

class ApexChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [70],
            options: {
                chart: {
                    height: 350,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '70%',
                        }
                    },
                },
                labels: ['Genel Ortalama'],
            },


        };
    }
    fetchData = () => {
        axios.get('https://edunimbus-django-backend.onrender.com/users/17/')
            .then((response) => {
                const allSubCat = response.data.result.data3;
                this.setState({
                    series: [allSubCat.result * 100],
                });
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        return (
            <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height={350} />
            </div>
        );
    }
}

export default ApexChart