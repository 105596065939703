import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col } from 'reactstrap';
import Rapo1 from './Pages/Rapor1';
import Rapor2 from './Pages/Rapor2';
import Rapor3 from './Pages/Rapor3';
import Rapor4 from './Pages/Rapor4';
import Rapor5 from './Pages/Rapor5';
import RaporResult from './Pages/RaporResult';

const UserDetail = () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const defaultStyle = {
    fontWeight: 'bold',
    color: 'gray',
  };

  const activeStyle = {
    fontWeight: 'bold',
    color: '#007bff'
  };

  return (
    <div className="content">
      <Nav tabs>
        <NavItem style={{ 'cursor': 'pointer' }}>
          <NavLink
            style={activeTab === '1' ? activeStyle : defaultStyle}
            onClick={() => toggleTab('1')}
          >
            Çalışma Raporu
          </NavLink>
        </NavItem>
        <NavItem style={{ 'cursor': 'pointer' }}>
          <NavLink
            style={activeTab === '2' ? activeStyle : defaultStyle}
            onClick={() => toggleTab('2')}
          >
            Bilişsel Analiz Raporu
          </NavLink>
        </NavItem>
        <NavItem style={{ 'cursor': 'pointer' }}>
          <NavLink
            style={activeTab === '3' ? activeStyle : defaultStyle}
            onClick={() => toggleTab('3')}
          >
            Zaman Süreci Raporu / Aylık
          </NavLink>
        </NavItem>
        <NavItem style={{ 'cursor': 'pointer' }}>
          <NavLink
            style={activeTab === '4' ? activeStyle : defaultStyle}
            onClick={() => toggleTab('4')}
          >
            Zaman Süreci Raporu / Yıllık
          </NavLink>
        </NavItem>
        <NavItem style={{ 'cursor': 'pointer' }}>
          <NavLink
            style={activeTab === '5' ? activeStyle : defaultStyle}
            onClick={() => toggleTab('5')}
          >
            Bilişsel Analiz Raporu
          </NavLink>
        </NavItem>
        <NavItem style={{ 'cursor': 'pointer' }}>
          <NavLink
            style={activeTab === '6' ? activeStyle : defaultStyle}
            onClick={() => toggleTab('6')}
          >
            Sonuç
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Rapo1 />
        </TabPane>
        <TabPane tabId="2">
          <Rapor2 />
        </TabPane>
        <TabPane tabId="3">
          <Rapor3 />
        </TabPane>
        <TabPane tabId="4">
          <Rapor4 />
        </TabPane>
        <TabPane tabId="5">
          <Rapor5 />
        </TabPane>
        <TabPane tabId="6">
          <Row>
            <Col sm="12">
              <RaporResult />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default UserDetail;
