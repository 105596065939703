import axios from "axios";
import { useEffect, useState } from "react";

export default function RaporResult() {
    const [data, setData] = useState(null);

    useEffect(() => {
        axios.get("https://edunimbus-django-backend.onrender.com/users/17/")
            .then((response) => {
                setData(response.data.result.data9);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    return (
        <>
            <h3 className="w-50 mx-auto text-center" style={{'marginTop': '75px'}}>
                {data && (
                    data.genre
                )}
            </h3>
            <h6 className="w-50 mx-auto text-center" style={{'marginTop': '15px'}}>
                Toplam oranı : {data && (
                    data.percentile * 100
                )}
            </h6>
            <p className="w-75 mx-auto text-center">
                {data && (
                    data.report
                )}
            </p>
        </>
    )
}