import { LogoutCookie } from "lib/helper/redirect-helper";
import { useEffect } from "react";

function Logout() {
    useEffect(() => {
        LogoutCookie();

        const handleRedirect = () => {
            window.location.href = '/login';
        }

        setTimeout(() => {
            handleRedirect()
        }, 1000)
    })

    return (
        <>
            <div className="content">
                <p>Yönlendiriliyor</p>
            </div>
        </>
    )
}

export default Logout;