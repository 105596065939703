import Cookies from "js-cookie";

export function SetLoggedUser(email) {
    Cookies.set('u_email', email);
    Cookies.set('u_id', (email === "teacher@miamo.com" ? "1" : "2"));
    Cookies.set('u_role', (email === "teacher@miamo.com" ? "teacher" : "student"));

    if (Cookies.get('u_id')) {
        return true
    }

    return false;
}

export function GetRole() {
    return Cookies.get('u_role');
}

export function GetId() {
    return Cookies.get('u_id')
}

export function GetEmail() {
    return Cookies.get('u_email')
}

export function RemoveCookie() {
    console.log('buraya geldi')
    Cookies.remove('u_id')
    Cookies.remove('u_email')
    Cookies.remove('u_role')
}

export function SetUserIdForData(id) {
    Cookies.set('user_data_id', id)
}