import PieChartUsed from "components/UserCharts/PieChartUsed"
import { Col, Row } from "reactstrap"

function Rapo1() {
    return (
        <>
            <Row>
                <Col sm="12">
                    <PieChartUsed />
                </Col>
            </Row>
        </>
    )
}

export default Rapo1