import axios from "axios";
import ApexChartAllStudent from "components/UserCharts/AllCatALLStudentChart";
import { Component } from "react";
import { Col, Row } from "reactstrap";

class Rapor4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            months: [],
            data: [],
            labels: [],
            totalScores: {},
            dataLoaded: false,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        axios
            .get('https://edunimbus-django-backend.onrender.com/users/17/')
            .then((response) => {
                const monthlyData = response.data.result.data8;
                const months = monthlyData.map((item) => item.month);
                const labels = monthlyData.map((item) => {
                    const groupedData = {};
                    item.monthlyGameData.forEach((gameData) => {
                        switch (gameData.genreID) {
                            case 1:
                            case 2:
                            case 3:
                                groupedData['Görsel Uzamsal'] = groupedData['Görsel Uzamsal'] || [];
                                groupedData['Görsel Uzamsal'].push(gameData);
                                break;
                            case 4:
                            case 5:
                                groupedData['Matematiksel Mantıksal'] = groupedData['Matematiksel Mantıksal'] || [];
                                groupedData['Matematiksel Mantıksal'].push(gameData);
                                break;
                            case 6:
                                groupedData['Müziksel Ritmik'] = groupedData['Müziksel Ritmik'] || [];
                                groupedData['Müziksel Ritmik'].push(gameData);
                                break;
                            case 7:
                            case 8:
                            case 9:
                                groupedData['Dikkat Hafıza'] = groupedData['Dikkat Hafıza'] || [];
                                groupedData['Dikkat Hafıza'].push(gameData);
                                break;
                            case 10:
                                groupedData['Sözel Dilbilimsel'] = groupedData['Sözel Dilbilimsel'] || [];
                                groupedData['Sözel Dilbilimsel'].push(gameData);
                                break;
                            default:
                                break;
                        }
                    });

                    return groupedData;
                });

                const data = labels[0]

                const label = [];
                const points = [];

                for (const category in data) {
                    const categoryData = data[category];
                    let totalPoints = 0;

                    categoryData.forEach((item) => {
                        totalPoints += item.totalGamePoints;
                    });

                    label.push(category);
                    points.push(totalPoints);
                }

                this.setState({ data: points })
                this.setState({ labels: label })
                this.setState({ months });
                this.setState({ dataLoaded: true })
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };


    render() {
        if (!this.state.dataLoaded) {
            return <p>Veriler Yükleniyor</p>;
        }

        const chartData = this.state.data.map((dataItem, index) => {
            if (dataItem) {
                const labelItem = this.state.labels[index];
                return {
                    name: labelItem,
                    data: [dataItem]
                };
            }
            return null;
        }).filter(item => item !== null);

        return (
            <>
                {
                    this.state.data && (
                        <Row style={{ 'marginTop': '75px' }}>
                            <Col sm="12">
                                <ApexChartAllStudent data={chartData} months={this.state.months} />
                            </Col>
                        </Row>
                    )
                }
            </>
        )
    }
}

export default Rapor4