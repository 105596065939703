import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Nav } from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";

import logo from "../../assets/img/Logomiamo.png";
import { GetRole } from "lib/helper/cookie-helper";
import { GetEmail } from "lib/helper/cookie-helper";

var ps;

function Sidebar(props) {
  const location = useLocation();
  const sidebar = React.useRef();
  const email = GetEmail();
  const role = GetRole();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });



  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo">
      <img src={logo} alt="react-logo" />
        <a
          href="/"
          className="simple-text logo-normal text-center" style={{ 'fontSize': '12px'}}
        >
          {email}  <br /> <span className="text-success bolder" >{role === 'student' ? 'Öğrenci' : 'Öğretmen'}</span>
        </a>
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
        <Nav>
          {props.routes.map((prop, key) => {
            return (
              <li
                className={
                  activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                }
                key={key}
              >
                {
                  prop.showSidebar && (<>
                    <NavLink to={prop.layout + prop.path} className="nav-NavLink">
                      <i className={prop.icon} />
                      <p>{prop.name}</p>
                    </NavLink>
                  </>)
                }
              </li>
            );
          })}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
