import { SetLoggedUser } from "lib/helper/cookie-helper";
import React, { useState } from "react";
import { Col, Row } from "reactstrap";

function Login() {
  const redirectHomepage = () => {
    window.location.href = "/admin/dashboard";
  };

  const [email, setEmail] = useState("teacher@miamo.com");
  const [password, setPassword] = useState("");

  const handleSubmit = () => {
    if (
      (email === "teacher@miamo.com" && password === "teacher123") ||
      (email === "student@miamo.com" && password === "student123")
    ) {
      SetLoggedUser(email);
      redirectHomepage();
    } else {
      alert("Hatalı email veya şifre!");
    }
  };

  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
      <Row className="w-100">
        <Col
          xs={12}
          md={6}
          className="d-flex align-items-center justify-content-center"
        >
          <div className="text-center">
            <img
              alt="logo"
              src={require("assets/img/Miamo4kids.png")}
              className="img-fluid"
            />
          </div>
        </Col>
        <Col xs={12} md={6} className="bg-white d-flex align-items-center">
          <div className="container w-75">
            <form className="w-100 mx-auto border p-4 rounded bg-login-card">
              <div className="w-100 text-center my-4 h3">Miamo4Kids</div>
              <div className="form-outline mb-4">
                <label
                  className="form-label text-white"
                  htmlFor="form2Example1"
                  style={{ fontWeight: "500" }}
                >
                  E-posta Adresi
                </label>
                <input
                  type="email"
                  id="form2Example1"
                  className="form-control border border-white"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="form-outline mb-4">
                <label className="form-label text-white" htmlFor="form2Example2">
                Şifre
                </label>
                <input
                  type="password"
                  id="form2Example2"
                  className="form-control border border-white"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="w-100 text-center">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="btn btn-block mb-4 w-50 mx-auto border border-white hover-none"
                >
                  Giriş Yap
                </button>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
