import axios from "axios";
import ApexChart from "components/UserCharts/RadialBar";
import ApexChartSubCat from "components/UserCharts/RadialBar-SubCategories";
import { Component } from "react";
import { Col, Row } from "reactstrap";

class Rapor2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            labels: [],
            dataLoaded: false
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        axios.get('https://edunimbus-django-backend.onrender.com/users/17/')
            .then((response) => {
                const allSubCat = response.data.result.data4;
                const groupedData = {};
                allSubCat.forEach(item => {
                    switch (item.genreID) {
                        case 1:
                        case 2:
                        case 3:
                            groupedData['Görsel Uzamsal'] = groupedData['Görsel Uzamsal'] || [];
                            groupedData['Görsel Uzamsal'].push(item);

                            break;
                        case 4:
                        case 5:
                            groupedData['Matematiksel Mantıksal'] = groupedData['Matematiksel Mantıksal'] || [];
                            groupedData['Matematiksel Mantıksal'].push(item);
                            break;
                        case 6:
                            groupedData['Müziksel Ritmik'] = groupedData['Müziksel Ritmik'] || [];
                            groupedData['Müziksel Ritmik'].push(item);
                            break;
                        case 7:
                        case 8:
                        case 9:
                            groupedData['Dikkat Hafıza'] = groupedData['Dikkat Hafıza'] || [];
                            groupedData['Dikkat Hafıza'].push(item);
                            break;
                        case 10:
                            groupedData['Sözel Dilbilimsel'] = groupedData['Sözel Dilbilimsel'] || [];
                            groupedData['Sözel Dilbilimsel'].push(item);
                            break;
                        default:
                            break;
                    }
                });
                const totalPercentages = {};

                for (const category in groupedData) {
                    const categoryData = groupedData[category];
                    const totalPercentage = categoryData.reduce((total, item) => total + item.percentile, 0);
                    totalPercentages[category] = totalPercentage;
                }

                const labels = Object.keys(totalPercentages);
                const data = labels.map(category => totalPercentages[category]);

                this.setState({
                    data: data,
                    labels: labels,
                    dataLoaded: true
                });

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }

    render() {
        if (!this.state.dataLoaded) {
            return <p>Veriler Yükleniyor</p>
        }
        return (
            <>
                {
                    this.state.data && this.state.labels && (
                        <>
                            <Row className="d-flex w-100 justify-content-around " style={{ 'marginTop': '50px' }}>
                            <h3 className='fw-bolder my-2'>
                            Hangi Bilişsel Alanda Yüzde Kaçlık Dilimde
                    </h3>
                    <br/>
                              
                                <Col className="bg-dark rounded" sm={12} md={3}>
                                    <ApexChart data={this.state.data} />
                                </Col>
                                <Col className="bg-dark rounded" sm={12} md={3}>
                                    <ApexChartSubCat data={this.state.data[0]} labels={this.state.labels[0]} />
                                </Col>
                                <Col className="bg-dark rounded" sm="12" md={3}>
                                    <ApexChartSubCat data={this.state.data[1]} labels={this.state.labels[1]} />
                                </Col>
                            </Row>
                            <Row className="d-flex w-100 justify-content-around my-4">
                                <Col className="bg-dark rounded" sm={12} md={3}>
                                    <ApexChartSubCat data={this.state.data[2]} labels={this.state.labels[2]} />
                                </Col>
                                <Col className="bg-dark rounded" sm={12} md={3}>
                                    <ApexChartSubCat data={this.state.data[3]} labels={this.state.labels[3]} />
                                </Col>
                                <Col className="bg-dark rounded" sm="12" md={3}>
                                    <ApexChartSubCat data={this.state.data[4]} labels={this.state.labels[4]} />
                                </Col>
                            </Row>
                        </>
                    )
                }
            </>
        );
    }
}

export default Rapor2;