function StudentTable() {
  return (
    <div>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            Öğrenci Takip Tablosu
          </span>
        </h3>
      </div>
      <div className="card-body py-3">
        <div>
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr className="fw-bold text-muted">
                <th className="w-25px">
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                      data-kt-check="true"
                      data-kt-check-target=".widget-9-check"
                    />
                  </div>
                </th>
                <th className="min-w-150px">Öğrenciler</th>
                <th className="min-w-140px">Okul</th>
                <th className="min-w-120px">Tamamlanan görevler</th>
                <th className="min-w-120px"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input widget-9-check"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
          
                    <div className="d-flex justify-content-start flex-column">
                      <a
                        href="/admin/user-detail"
                        className="text-dark fw-bold text-hover-primary fs-6"
                      >
                        Aleyna Yılmaz
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <p className="text-dark fw-bold text-hover-primary d-block fs-6">
                    YÖM Okulları
                  </p>
                </td>
                <td className="text-end">
                  <div className="d-flex flex-column w-100 me-2">
                    <div className="d-flex flex-stack mb-2">
                      <span className="text-muted me-2 fs-7 fw-semibold">
                        50%
                      </span>
                    </div>
                    <div className="progress h-6px w-100">
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{ width: "50%" }}
                      ></div>
                    </div>
                  </div>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input widget-9-check"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                      <a
                        href="/admin/user-detail"
                        className="text-dark fw-bold text-hover-primary fs-6"
                      >
                        Derin Kaptan
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <a className="text-dark fw-bold text-hover-primary d-block fs-6">
                    YÖM Okulları
                  </a>
                </td>
                <td className="text-end">
                  <div className="d-flex flex-column w-100 me-2">
                    <div className="d-flex flex-stack mb-2">
                      <span className="text-muted me-2 fs-7 fw-semibold">
                        70%
                      </span>
                    </div>
                    <div className="progress h-6px w-100">
                      <div
                        className="progress-bar bg-danger"
                        role="progressbar"
                        style={{ width: "70%" }}
                      ></div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-end flex-shrink-0"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input widget-9-check"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                      <a
                        href="/admin/user-detail"
                        className="text-dark fw-bold text-hover-primary fs-6"
                      >
                        Mehmet Kıran
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <a className="text-dark fw-bold text-hover-primary d-block fs-6">
                    YÖM Okulları
                  </a>
                </td>
                <td className="text-end">
                  <div className="d-flex flex-column w-100 me-2">
                    <div className="d-flex flex-stack mb-2">
                      <span className="text-muted me-2 fs-7 fw-semibold">
                        60%
                      </span>
                    </div>
                    <div className="progress h-6px w-100">
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        style={{ width: "60%" }}
                      ></div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-end flex-shrink-0">
                    <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"></a>
                    <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"></a>
                    <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"></a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input widget-9-check"
                      type="checkbox"
                      value="1"
                    />
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                      <a
                        href="/admin/user-detail"
                        className="text-dark fw-bold text-hover-primary fs-6"
                      >
                        Nilay Doğru
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <a className="text-dark fw-bold text-hover-primary d-block fs-6">
                    YÖM Okulları
                  </a>
                </td>
                <td className="text-end">
                  <div className="d-flex flex-column w-100 me-2">
                    <div className="d-flex flex-stack mb-2">
                      <span className="text-muted me-2 fs-7 fw-semibold">
                        50%
                      </span>
                    </div>
                    <div className="progress h-6px w-100">
                      <div
                        className="progress-bar bg-warning"
                        role="progressbar"
                        style={{ width: "50%" }}
                      ></div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-end flex-shrink-0">
                    <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"></a>
                    <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"></a>
                    <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"></a>
                  </div>
                </td>
              </tr>
              <tr></tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default StudentTable;
