import TwoBarChart from "components/UserCharts/TwoBarChart";
import { Col, Row } from "reactstrap";

export default function Rapor5() {
    return (
        <Row>
            <Col sm="12">
                <TwoBarChart />
            </Col>
        </Row>
    )
}