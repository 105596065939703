import React from "react";
import ReactApexChart from "react-apexcharts";

class SubCatLineChart extends React.Component {
  constructor(props) {
    super(props);


    this.state = {
      series: [
        {
          name: "Puan",
          data: this.props.data,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "area",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#77B6EA"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: this.props.title,
          align: "left",
        },
        grid: {
          // borderColor: "#FF0000",
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: [
            this.props.cat
          ],
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: "#808080",
              fontSize: "12px",
            },
          },
          crosshairs: {
            position: "front",
            stroke: {
              color: "#77B6EA",
              width: 1,
              dashArray: 3,
            },
          },
          tooltip: {
            enabled: true,
            formatter: undefined,
            offsetY: 0,
            style: {
              fontSize: "12px",
            },
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: "#808080",
              fontSize: "12px",
            },
          },
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0,
            },
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
        tooltip: {
          style: {
            fontSize: "12px",
          },
          y: {
            formatter: function (val) {
              return +val + " puan";
            },
          },
        },
      },
    };
  }

  render() {

    if (!this.props.data || this.props.data.length === 0) {
      return <p>Veriler Yüklenemedi</p>;
    }

    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={350}
        />
      </div>
    );
  }
}

export default SubCatLineChart;
