import React from "react";

const { default: ReactApexChart } = require("react-apexcharts");

class ApexChartAllStudent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            series: this.props.data,
            options: {
                chart: {
                    height: 350,
                    type: 'area'
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    type: 'string',
                    categories: this.props.months
                },
            },
        };
    }

    render() {
        return (
            <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={350} />
            </div>
        );
    }
}

export default ApexChartAllStudent