// import axios from 'axios';
import React from 'react';
const { default: ReactApexChart } = require("react-apexcharts");

class ApexChartSubCat extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [this.props.data],
            options: {
                chart: {
                    height: 350,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '70%',
                        }
                    },
                },
                labels: [this.props.labels],
            },
        };
    }

    render() {
        if (!this.props.data) {
            return <p>Veriler Yüklenemedi</p>
        }

        return (
            <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height={350} />
            </div>
        );
    }
}

export default ApexChartSubCat